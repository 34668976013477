export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    color: 'text',
    '.navMenu': {
      // backgroundColor: ['', '', '', 'rgb(209 50 70 / 81%)'],
      color: ['white', '', '', 'primary'],
      backgroundColor: ['', '', '', 'transparent'],
      border: 'none',
      justifyContent: ['', '', '', 'flex-end']
    },
    '.navItemDropdownMenu': {
      backgroundColor: ['transparent', '', '', 'background'],
      marginTop: ['', '', '', '0rem'],
      width: 'fit-content'
    },
    '.container': {
      background: 'unset',
      backgroundColor: 'background',
      height: 'unset',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem']
    },
    '.containerScrolled': {
      background: 'unset',
      backgroundColor: 'background',
      padding: ['0.5rem', '0.5rem', '0.5rem', '0.5rem', '0.5rem']
    },

    '.hamburger': {
      mr: '0rem',
      '> div': {
        backgroundColor: 'text'
      }
    },
    '.hamburgerOpen': {
      mr: '0rem'
    },
    '.smallNavMenu': {
      '> div': {
        color: 'text'
      }
    },
    '.navItem': {
      margin: ['1rem 0.4rem', '', '', '1rem 0.4rem', '1rem 0.5em'],
      a: {
        fontSize: ['1rem', '', '', '0.8rem', '0.8rem', '0.9rem', '0.9rem'],
        color: 'inherit',
        letterSpacing: ['0px', '', '', '-1px', '-0.5px']
      }
    },
    '.hamburger > div': {},

    '.navMenuLogo': {},
    '.navMenuOpen': {
      '.seperatorLine': {},
      '.navItem': {}
    },
    '.navBlockOpen': {},
    '.phoneSocialContainer': {},
    '.socialIconsContainer, .phoneContainer': {
      '.smallNavHeading': {}
    },
    '.phoneContainer': {
      display: 'none'
    },

    '.logo': {
      top: '1rem',
      position: 'static',
      transform: 'unset',
      padding: '0rem',
      img: {
        padding: '0rem',
        maxHeight: '80px',
        maxHeight: ['80px', '', '', '', '100px', '120px'],
        height: 'auto'
      }
    },
    '.logoScrolled': {
      position: 'static',
      transform: 'unset',
      padding: '0rem',
      img: {
        padding: '0rem',
        maxHeight: ['55px', '', '', '', '65px'],
        height: 'auto'
      }
    }
  },

  footer: {
    padding: '0rem',
    '.subFooter': {
      backgroundColor: 'background',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: '2rem',
      padding: '3rem 0rem',
      // locations
      '.locationBoxes': {
        justifyContent: 'flex-start',
        // flexGrow: '1',
        padding: ['2rem 1rem', '2rem 1rem', '', '']
        // marginBottom: ['1rem', '', '0rem'],
      },
      '.box': {
        display: 'flex',
        flexDirection: 'column',
        padding: '0rem 0.5rem',
        '.title': {
          color: 'maroon',
          fontSize: ['1.1rem', '1.25rem', '1.5rem'],
          marginBottom: '0.75rem',
          textTransform: 'uppercase',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        },
        '.text': {
          margin: '0rem 0rem 0.5rem',
          fontSize: ['0.8rem', '0.9rem', '1rem']
        },
        '.socialIconsContainer': {
          display: 'none'
        }
      },

      '.phone': {
        textAlign: 'center'
      },

      // hours
      '.innerHoursContainer': {
        padding: ['1rem', '1rem', '', ''],
        flexDirection: 'row',
        display: 'flex',
        // flexGrow: '1',
        flexWrap: 'wrap',
        '.heading': {
          display: 'flex',
          alignItems: 'center',
          flex: 'nowrap',
          color: 'maroon',
          fontSize: ['1.1rem', '1.25rem', '1.5rem'],
          textTransform: 'uppercase',
          width: '100%',
          marginBottom: '1rem',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
          // '::after': {
          //   width: '100%',
          //   content: "''",
          //   marginLeft: '1rem',
          //   // border: 'solid 1px',
          //   backgroundColor: 'maroon',
          //   borderColor: 'maroon',
          //   height: '2px'
          // }
        },
        '.typeContainer': {
          flexGrow: '1'
        },
        '.typelabel': {
          textAlign: 'center'
        },
        '.dayContainer': {
          textAlign: 'center'
        },
        '.day': {
          fontSize: ['0.9rem', '1rem', '1.1rem'],
          color: 'maroon',
          fontWeight: 'bold'
        },
        '.time': {
          fontSize: ['0.8rem', '0.9rem', '1rem'],
          color: 'black'
        }
      }
    },

    '.image': {
      maxHeight: '35px'
    },
    '.poweredBy': {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '.gonationLogo': {
        filter: 'brightness(0)',
        width: 'fit-content',
        marginLeft: '0.5rem'
      }
    },
    backgroundColor: 'light',
    '.socialContainer': {
      svg: {
        width: '20px',
        height: '20px',
        margin: '0.5rem',
        path: {
          fill: 'maroon'
        }
      }
    }
  },

  ctaWidget: {
    zIndex: '999',
    color: 'light',
    display: 'none'
  },

  pageHero: {
    height: '90vh',
    '.content': {
      padding: '1rem'
    },
    h1: {
      variant: 'customVariants.title',
      color: 'white'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.75rem', '', '2rem'],
    color: 'primary',
    textTransform: 'uppercase',
    fontFamily: 'body'
  },

  subtitle: {
    color: 'primary',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },

  text: {},

  beforeAfterIcons: {
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_ocean.png)',
      backgroundSize: 'contain',
      width: '50px',
      height: '50px',
      backgroundRepeat: 'no-repeat'
    },
    '::after': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_ocean.png)',
      backgroundSize: 'contain',
      width: '50px',
      height: '50px',
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(180deg)',
      order: '9'
    }
  },

  sideBySide1: {
    maxHeight: ['', '', '', '60vh'],
    '.lazyload-wrapper': {},
    '.content': {
      '::before': {
        content: "''",
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_maroon.png)',
        backgroundSize: 'contain',
        width: '50px',
        height: '50px',
        backgroundRepeat: 'no-repeat'
      },
      textAlign: 'center',
      alignItems: 'center',
      padding: ['2rem 1rem', '', '2rem', '4rem']
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '2'
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      left: '50%',
      margin: '0rem',
      transform: 'translate(-50%,-50%)'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    color: 'light',
    '.shoutWidget': {
      display: 'none'
    },

    '.ctaButton': {
      variant: 'buttons.secondary'
    },

    '.socialIcons': {
      position: 'absolute',
      bottom: '3rem',
      left: '3rem',
      svg: {
        margin: '0.7rem',
        width: '20px',
        height: '20px'
      }
    },

    // ? slick slider styles
    '.slick-slider': {
      '.slick-arrow': {
        display: 'none !important'
      },

      '.slick-dots li.slick-active button:before': {
        color: 'secondary'
      },
      '.slick-dots li button:before': {
        fontSize: '15px'
      }
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    '.date': {
      color: 'tertiary'
    },
    flexDirection: ['column', 'column', 'row'],
    '.imageContainer': {
      width: ['100%', '100%', '30%'],
      maxHeight: ['', '', '55vh'],
      order: ['3', '', 'unset']
    },
    '.image': {
      padding: ['0rem', '', '1rem', '2rem'],
      objectFit: 'cover',
      objectFit: 'contain'
    },
    '.content': {
      '::before': {
        content: "''",
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_maroon.png)',
        backgroundSize: 'contain',
        width: '50px',
        height: '50px',
        backgroundRepeat: 'no-repeat'
      },
      backgroundColor: ['', '', '#f9f4ee'],
      color: 'text',
      padding: ['1rem 4rem 1rem 1rem', '', '2rem 5rem 2rem 2rem'],
      margin: ['', '', '2rem 1rem'],
      width: ['100%', '100%', '70%']
    },
    '.text': {
      variant: 'customVariants.text',
      lineHeight: '1.4',
      color: ['dark', '', 'dark'],
      fontSize: ['1rem', '1rem', '1.25rem'],
      letterSpacing: '1px',
      fontFamily: 'heading'
    },
    '.title': {
      variant: 'customVariants.title',
      width: ['3rem', '', '', '4rem'],
      textTransform: 'uppercase',
      color: 'light',
      fontSize: ['1rem', '1.1rem', '1.2rem', '1.2rem']
      // letterSpacing: '3px',
    },
    '.date': {
      color: 'light',
      display: 'none'
    },

    '.shoutCTA': {
      variant: 'buttons.primary'
    }
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1'
  },

  homepageHappyHour: {
    backgroundAttachment: ['unset', '', '', 'unset'],
    padding: ['4rem 1rem', '', '6rem 1rem', '8rem 1rem'],

    '.section': {
      variant: 'customVariants.beforeAfterIcons'
    },

    '.title': {
      variant: 'customVariants.title',
      fontFamily: 'display',
      fontSize: ['2rem', '2.5rem', '3.5rem'],
      letterSpacing: '1px'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      marginBottom: '0rem'
    },
    '.text': {
      variant: 'customVariants.text',
      display: 'flex',
      flexDirection: 'column',
      p: {
        margin: '0rem',
        fontSize: '1.2rem',
        strong: {
          color: '#6d2b1a'
        }
      }
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  homepageCatering: {
    color: 'white',
    padding: '8rem 1rem',
    backgroundAttachment: ['', '', '', 'fixed'],

    '.section': {
      '::before': {
        content: "''",
        backgroundImage:
          'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_white.png)',
        backgroundSize: 'contain',
        width: '50px',
        height: '50px',
        backgroundRepeat: 'no-repeat'
      },

      maxWidth: '400px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'inherit'
    },
    '.text': {
      variant: 'customVariants.text',
      // display: 'flex',
      // flexDirection: 'column',
      color: 'inherit',
      '*': {
        margin: '0rem',
        fontSize: '1.2rem',
        color: 'inherit'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  homepageHours: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutBoxes: {
    '& div > div > div ': {
      boxShadow: '2px 2px 8px lightgrey'
    }
  },

  aboutSection: {
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    backgroundColor: '#fdf5e4',
    maxWidth: 'unset',
    margin: '0 auto',
    '.backToMenuBtn': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      padding: '0.74rem 2rem'
    },
    '.dropdownContainer': {
      display: 'none !important'
    },
    '.section-cell, .menuCell, .cellImage': {
      borderRadius: '0px',
      border: 'none',
      background: 'transparent'
    },
    '.cellName': {
      color: 'maroon',
      textAlign: 'center'
    },

    '.menuContainer': {},
    '.tabsRow': {},
    '#menuTab': {
      variant: 'buttons.secondary',
      margin: '0.5rem',

      ':hover': {
        color: 'secondary',
        backgroundColor: 'transparent'
      }
    },

    '.menuSectionTitle': {
      variant: 'customVariants.title',
      color: 'maroon',
      textAlign: 'center',
      margin: '1rem 0rem',
      padding: '0rem 1rem'
    },

    '.allInContainerWrapper > .allInContainer > .allInContainer': {
      '.menuSectionTitle': {
        variant: 'customVariants.title',
        borderBottom: 'solid 1px',
        borderColor: 'maroon',
        color: 'maroon',
        textAlign: 'left',
        margin: '1rem 0rem'
      }
    },
    '.menuItemContainer': {
      margin: '0rem 0rem 0.75rem',
      padding: '0rem 1rem'
    },
    '.menuItemInnerContainer': {
      margin: '0rem'
    },

    '.menuItemName': {
      textTransform: 'uppercase',
      marginBottom: '0rem',
      fontSize: ['1.1rem', '1.2rem', '1.25rem']
    }
  },

  contactUsBar: {
    backgroundColor: '#f9f4ee',
    padding: '0rem 1rem',
    '.content': {
      display: 'flex',
      alignItems: 'center',
      padding: '0rem 1rem',
      borderRight: '0px'
    },
    '.title': {
      marginBottom: '0rem',
      color: 'primary'
    }
  },

  menuCTAS: {
    '.ctaLink': {
      variant: 'buttons.primary',
      borderRadius: '0px',
      margin: '0 auto',
      width: '100%',
      textAlign: 'center'
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {},
    '.albumTitle': {}
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {},
    '.eventItemImage': {},
    '.eventDays, .eventDate': {}
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contact1: {
    variant: 'customVariants.homepageCatering',
    'div.section': {
      variant: 'customVariants.beforeAfterIcons'
    },
    color: 'black'
  },

  contactForm: {
    backgroundColor: '#dfd4ca',
    color: 'black',
    order: '2',
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_maroon.png)',
      backgroundSize: 'contain',
      width: '50px',
      height: '50px',
      backgroundRepeat: 'no-repeat'
    },
    '::after': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1628006564/sites/brasitas/Brasitas_flower_maroon.png)',
      backgroundSize: 'contain',
      width: '50px',
      height: '50px',
      backgroundRepeat: 'no-repeat',
      transform: 'rotate(180deg)'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    form: {
      input: {
        borderColor: 'white',
        backgroundColor: 'rgba(255,255,255,0.5)'
      },

      select: {
        borderColor: 'white',
        backgroundColor: 'rgba(255,255,255,0.5)'
      },
      textarea: {
        borderColor: 'white',
        backgroundColor: 'rgba(255,255,255,0.5)'
      },
      '.submitBtn': {
        variant: 'buttons.primary'
      }
    }
  },

  locationMap: {
    order: '2',
    h3: {
      variant: 'customVariants.title'
    },

    '.dayofWeekText': {}
  },

  // ? =========================
  // ? ====  Catering Menu  ====
  // ? =========================

  catering1: {},

  cateringBoxes: {
    '.box': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      p: '1rem',
      '.image': {
        maxHeight: '150px',
        objectFit: 'contain'
      }
    }
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
